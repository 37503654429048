@use 'styles/breakpoints' as *;

.wrapper {
  width: 2rem;
  height: 2rem;
  z-index: 10;
  background-color: transparent;
  outline: none;
  position: relative;

  &.neutral {
    color: var(--clr-dark-gray);
  }

  &.inverted {
    color: var(--clr-white);
  }
}

.bar {
  position: absolute;
  top: 50%;
  right: 0;
  height: 0.25rem;
  background-color: currentColor;
  transition: all 0.3s ease-in;
  width: var(--width);
}

.topBar {
  --width: 1.625rem;
  transform: translate(0, calc(-0.375rem - 0.125rem));

  @include desktop {
    --width: 2rem;
  }
}

.centerBar {
  --width: 0.8125rem;

  @include desktop {
    --width: 1rem;
  }
}

.bottomBar {
  --width: 1.1875rem;
  transform: translate(0, calc(0.375rem + 0.125rem));

  @include desktop {
    --width: 1.5rem;
  }
}

.open {
  & > .topBar {
    --width: 1.5rem;
    transform: rotate(-45deg) translate(2px, -1px);

    @include desktop {
      --width: 1.875rem;
    }
  }
  & > .centerBar {
    opacity: 0;
  }
  & > .bottomBar {
    --width: 1.5rem;
    transform: rotate(45deg) translate(-1px, -2px);

    @include desktop {
      --width: 1.875rem;
    }
  }
}

.circle:hover::before {
  @include desktop {
    opacity: 1;
  }
}

.circle::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4rem;
  width: 4rem;
  border: 0.25rem solid currentColor;
  border-radius: 50%;
  transition-duration: 0.4s;
  opacity: 0;
  transform: translate(-25%, -50%);
}
