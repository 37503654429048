@use 'styles/breakpoints' as *;

.container {
  position: relative;
  transition: all 3s ease;
}

.wrapper {
  font-family: var(--inter-font);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: normal;
  padding-top: 7rem;
  padding-bottom: 1rem;
  z-index: 11;
  pointer-events: none;

  &.show {
    pointer-events: all;
  }

  @include desktop {
    padding-top: 4rem;
    flex-direction: column;
  }
}

.backdrop {
  position: fixed;
  transform: translate(300vw, -300vh);
  width: 300vw;
  height: 300vh;
  top: 0;
  right: 0;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border-radius: 100%;
  z-index: 10;
  transition: all 0.8s ease;
}

.open {
  transform: translate(100vw, -100vh);
}

.menuWrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  @include desktop {
    width: 100%;
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
    max-width: 90rem;
  }
}

.menuItemsContainer {
  gap: 1rem;
  margin-top: calc(-1 * var(--btn-padding-y));

  @include desktop {
    font-size: 1.25rem;
    line-height: 1;
    columns: 2;
    column-gap: 7.2rem;
  }
}

.addressWrapper {
  display: flex;
  gap: 1rem;
  color: var(--clr-white);
  flex-direction: column;
  margin-top: 2.5rem;

  @include desktop {
    flex-direction: column;
    gap: 4.25rem;
    margin-top: 0;
  }
}

.address {
  display: flex;
  flex-direction: column;
  font-size: var(--p-reg-size);
  font-style: normal;
}

.addressLabel {
  font-weight: bold;
  display: none;
  font-size: var(--h3-comp-size);
  margin-bottom: 0.75rem;

  @include desktop {
    display: block;
  }
}

.animatedContainer {
  transition: opacity 0.3s ease-in;
  align-items: center;
  justify-content: center;
  opacity: 0;
  height: 100%;
  overflow: auto;
  width: 100%;

  @include desktop {
    display: flex;
    flex-direction: row;
  }
}

.openAnimation {
  transition-delay: 300ms;
  pointer-events: all;
  opacity: 1;
}

.closeAnimation {
  transition-duration: 75ms;
  transition-delay: 75ms;
  pointer-events: none;
  opacity: 0;
}
