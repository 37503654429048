@use 'styles/breakpoints' as *;

.listItem {
  display: flex;
  align-items: center;

  &::after {
    content: attr(data-after);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    color: var(--clr-white);
    text-align: center;
    border-radius: 50%;
  }

  &.menu::after {
    font-size: var(--menu-item-after-font-size);
    width: var(--menu-item-after-width);
    height: var(--menu-item-after-height);
    margin-left: var(--menu-item-after-margin-left);
    background-color: rgba(#1e1e1e, 0.4);
  }

  &.navigation {
    &::after {
      font-size: 1.125rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.75rem;

      @include desktop {
        font-size: 1.5rem;
        width: 2rem;
        height: 2rem;
      }
    }

    &.neutral::after {
      background-color: var(--clr-dark-gray);
    }

    &.inverted::after {
      background-color: var(--clr-white);
      color: var(--clr-dark-gray);
    }
  }
}

.menuItem {
  text-transform: lowercase;
  &.menu {
    font-size: var(--menu-font-size);
  }

  &.navigation,
  &.footer {
    font-size: var(--menu-nav-font-size);
  }
}
